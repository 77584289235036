import React, { useState, useEffect } from "react";
import AdminMenu from "./AdminMenu";
import { ToastContainer } from "react-toastify";
import showError, { showMessage } from "./toast-message";
import axios from "axios";
import { useNavigate, useParams, Link } from "react-router-dom";
import BaseUrl from "./BaseUrl";
import { Spinner } from "react-bootstrap";
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';

export default function AdminEditProduct() {
  const navigate = useNavigate();
  const { productid } = useParams();

  const [title, setTitle] = useState("");
  const [modelNumber, setModelNumber] = useState("");
  const [photo, setPhoto] = useState(null);
  const [buyPrice, setBuyPrice] = useState("");
  const [MSRP, setMSRP] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const fetchProductDetail = async () => {
    try {
      const response = await axios.get(`${BaseUrl()}products/${productid}`);
      const productData = response.data;
      setTitle(productData.title);
      setModelNumber(productData.model_number);
      setBuyPrice(productData.buyPrice);
      setMSRP(productData.MSRP);
    } catch (error) {
      showError("Error fetching product details.");
      console.error("Error fetching product details:", error);
    }
  };

  useEffect(() => {
    fetchProductDetail();
  }); // Ensure fetch is called whenever productid changes

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    const formData = new FormData();
    formData.append("title", title);
    formData.append("model_number", modelNumber);
    if (photo) formData.append("photo", photo);
    formData.append("buyPrice", buyPrice);
    formData.append("MSRP", MSRP);

    try {
      const response = await axios.put(`${BaseUrl()}products/${productid}`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      if (response.status === 200) {
        showMessage("Product updated successfully!");
        navigate("/admin-product");
      }
    } catch (error) {
      showError("Failed to update product. Please try again later.");
      console.error("Error updating product:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="d-flex min-vh-100 bg-light">
      <AdminMenu />
      <div className="flex-grow-1 p-4">
        <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} />
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h4 className="fw-bold text-dark">Edit Product</h4>
          <Link to="/admin-product" className="btn btn-outline-secondary">
            <i className="bx bx-arrow-back me-2"></i> Back
          </Link>
        </div>

        <div className="card shadow mb-4">
          <div className="card-header" style={{ backgroundColor: '#003366', color: '#fff' }}>
            <h5 className="mb-0">Product Details</h5>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit} encType="multipart/form-data">
              <div className="row mb-4">
                <div className="col-md-6 mb-3">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      id="title"
                      placeholder="Title"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      required
                    />
                    <label htmlFor="title">Title</label>
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      id="model_number"
                      placeholder="Model Number"
                      value={modelNumber}
                      onChange={(e) => setModelNumber(e.target.value)}
                      required
                    />
                    <label htmlFor="model_number">Model Number</label>
                  </div>
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-md-6 mb-3">
                  <label htmlFor="photo" className="form-label">Select Photo (optional)</label>
                  <input
                    className="form-control"
                    type="file"
                    id="photo"
                    accept="image/*"
                    onChange={(e) => setPhoto(e.target.files[0])}
                  />
                </div>
                <div className="col-md-3 mb-3">
                  <div className="form-floating">
                    <input
                      type="number"
                      className="form-control"
                      id="buyPrice"
                      placeholder="Buy Price"
                      value={buyPrice}
                      onChange={(e) => setBuyPrice(e.target.value)}
                      min="0"
                      step="0.01"
                      required
                    />
                    <label htmlFor="buyPrice">Buy Price ($)</label>
                  </div>
                </div>
                <div className="col-md-3 mb-3">
                  <div className="form-floating">
                    <input
                      type="number"
                      className="form-control"
                      id="MSRP"
                      placeholder="MSRP"
                      value={MSRP}
                      onChange={(e) => setMSRP(e.target.value)}
                      min="0"
                      step="0.01"
                      required
                    />
                    <label htmlFor="MSRP">MSRP ($)</label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 text-end">
                  <button type="submit" className="btn" style={{ backgroundColor: '#007bff', color: '#fff' }} disabled={isSubmitting}>
                    {isSubmitting ? (
                      <>
                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                        <span className="ms-2">Updating...</span>
                      </>
                    ) : (
                      <>
                        <i className="bx bx-save me-2"></i> Update Product
                      </>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
