import React, { useState, useEffect } from "react";
import AdminMenu from "./AdminMenu";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import showError, { showMessage } from "./toast-message";
import axios from "axios";
import BaseUrl from "./BaseUrl";
import { Modal, Button } from "react-bootstrap";
import 'react-toastify/dist/ReactToastify.css'; // Ensure Toastify CSS is imported
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap CSS is imported
import 'boxicons/css/boxicons.min.css'; // Ensure BoxIcons CSS is imported

export default function AdminProduct() {
  const [products, setProducts] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [productToDelete, setProductToDelete] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  // Fetch products from API
  const fetchData = async () => {
    try {
      const response = await axios.get(`${BaseUrl()}products`);
      setProducts(response.data);
    } catch (error) {
      console.error("Error fetching products:", error);
      showError("Failed to fetch products. Please try again later.");
    }
  };

  // Handle delete button click
  const handleDeleteClick = (product) => {
    setProductToDelete(product);
    setShowDeleteModal(true);
  };

  // Confirm deletion
  const confirmDelete = async () => {
    if (!productToDelete) return;

    try {
      const response = await axios.delete(`${BaseUrl()}products/${productToDelete.id}`);
      if (response.status === 200) {
        showMessage("Product deleted successfully!");
        // Update the products list after deletion
        fetchData();
      }
    } catch (error) {
      console.error("Error deleting product:", error);
      showError("Failed to delete product. Please try again later.");
    } finally {
      setShowDeleteModal(false);
      setProductToDelete(null);
    }
  };

  // Cancel deletion
  const cancelDelete = () => {
    setShowDeleteModal(false);
    setProductToDelete(null);
  };

  return (
    <div className="d-flex">
      {/* Admin Menu */}
      <AdminMenu />

      {/* Main Content */}
      <div className="flex-grow-1 p-4">
        {/* Toast Notifications */}
        <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} />

        {/* Header */}
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h4 className="fw-bold">Products</h4>
          <Link to="/add-product" className="btn btn-primary">
            <i className="bx bx-plus-circle me-2"></i> Add Product
          </Link>
        </div>

        {/* Product Table */}
        <div className="card">
          <div className="card-header bg-primary text-white">
            <h5 className="mb-0">Existing Products</h5>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <table className="table table-striped table-hover align-middle">
                <thead className="table-light">
                  <tr>
                    <th>ID</th>
                    <th>Title</th>
                    <th>Model Number</th>
                    <th>Buy Price</th>
                    <th>MSRP</th>
                    <th>Photo</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {products.length > 0 ? (
                    products.map((item) => (
                      <tr key={item.id}>
                        <td>{item.id}</td>
                        <td>{item.title}</td>
                        <td>{item.model_number}</td>
                        <td>${item.buyPrice.toFixed(2)}</td>
                        <td>${item.MSRP.toFixed(2)}</td>
                        <td>
                          <img
                            src={`${BaseUrl()}images/${item.photo}`}
                            className="img-fluid rounded"
                            alt={item.title}
                            style={{ maxWidth: "100px", height: "auto" }}
                          />
                        </td>
                        <td>
                          <Link to={`/edit-product/${item.id}`} className="btn btn-sm btn-warning me-2" title="Edit Product">
                            <i className="bx bx-pencil"></i>
                          </Link>
                          <button
                            className="btn btn-sm btn-danger"
                            onClick={() => handleDeleteClick(item)}
                            title="Delete Product"
                          >
                            <i className="bx bx-trash"></i>
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="7" className="text-center">
                        No products found.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {/* Delete Confirmation Modal */}
        <Modal show={showDeleteModal} onHide={cancelDelete} centered>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Deletion</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to delete the product <strong>{productToDelete?.title}</strong>?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={cancelDelete}>
              Cancel
            </Button>
            <Button variant="danger" onClick={confirmDelete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}
