import React, { useState, useEffect } from "react";
import AdminMenu from "./AdminMenu";
import { ToastContainer } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import BaseUrl from "./BaseUrl";


export default function AddNewSale() {
    const navigate = useNavigate();

    const getCurrentDate = () => {
        const date = new Date();
        const year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();

        // Add leading zero to month and day if needed
        if (month < 10) {
            month = '0' + month;
        }
        if (day < 10) {
            day = '0' + day;
        }

        return `${year}-${month}-${day}`;
    };

    const [state, setState] = useState({
        EAddress: `MOHMEDSALIM MANSOORI \n179, KACHHIYAVAD, \nDEVJIBHAGATS, \nDHARAMSALA, KAMLEJIYAS, \nDELO, \nBhavnagar, Gujarat, 364001 India`,
        CAddress: '',
        InvoiceNo: '',
        Date: getCurrentDate(),
        IECCode: `2412011842`,
        GSTIN: `24AJEPM8902A1ZN`,
        PAN: `AJEPM8902A`,
        BuyersOrderNo: '',
        BuyerNotCosignee: '',
        PreCarriageBy: '',
        LUTNo: `AD240424014058H`,
        FinalDestination: '',
        VesselFlightNo: '',
        PortOfLoading: '',
        PortOfDischarge: '',
        DeliPay: '',
        ContainerNo: '',
        TotalAmount: '',
        subTotal: '',
        SalesTax: '',
        items: [{ description: '', HSNCode: '', Rate: '', quantity: '' }],
        products: [], 
    });
    
    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await axios.get(`${BaseUrl()}products`);
                setState(prevState => ({
                    ...prevState,
                    products: response.data
                }));
            } catch (error) {
                console.error("Error fetching products:", error);
            }
        };

        fetchProducts();
    }, []);

    const handleChange = (event, index) => {
        const { name, value } = event.target;
        const updatedItems = [...state.items];
        updatedItems[index][name] = value;
        setState(prevState => ({
            ...prevState,
            items: updatedItems
        }));

        calculateSubTotal(updatedItems);
    };

    const calculateSubTotal = (items) => {
        const subtotal = items.reduce((acc, item) => acc + (parseFloat(item.quantity) * parseFloat(item.Rate)), 0);
        const salesTax = parseFloat(state.SalesTax) || 0;
        const totalAmount = subtotal + salesTax;

        setState(prevState => ({
            ...prevState,
            subTotal: subtotal,
            TotalAmount: totalAmount
        }));
    };

    const handleAddItem = () => {
        setState(prevState => ({
            ...prevState,
            items: [...prevState.items, { description: '', HSNCode: '', Rate: '', quantity: '' }]
        }));
    };

    const handleDeleteItem = (index) => {
        const updatedItems = [...state.items];
        updatedItems.splice(index, 1);
        setState(prevState => ({
            ...prevState,
            items: updatedItems
        }));
        calculateSubTotal(updatedItems);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        
        try {
            const response = await axios.post(`${BaseUrl()}sales`, state);
            console.log(response.data);
            navigate('/sales'); // Navigate to the sales


        } catch (error) {
            console.error('Error:', error);
        }
    };


    return (
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <AdminMenu />
                <ToastContainer />
                <div className="layout-page">
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y">
                            <div className="row mb-3">
                                <div className="col-12 d-flex justify-content-between">
                                    <h4 className="fw-bold py-1 mb-1">Sales</h4>
                                    <a href="admin-sales.html" className="btn btn-primary">
                                        Back
                                    </a>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <h5 className="card-header">Add new sale</h5>
                                        <div className="card-body">
                                            <form onSubmit={handleSubmit}>
                                                <div className="row mb-3">
                                                    <div className="col-md-6">
                                                        <div className="form-floating">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="EAddress"
                                                                placeholder="Exporter's Address"
                                                                value={state.EAddress}
                                                                onChange={(event) =>
                                                                    setState((prevState) => ({
                                                                        ...prevState,
                                                                        EAddress: event.target.value,
                                                                    }))
                                                                }
                                                                required
                                                            />
                                                            <label>Exporter's Address</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-floating">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="CAddress"
                                                                placeholder="Consignee's Address"
                                                                value={state.CAddress}
                                                                onChange={(event) =>
                                                                    setState((prevState) => ({
                                                                        ...prevState,
                                                                        CAddress: event.target.value,
                                                                    }))
                                                                }
                                                                required
                                                            />
                                                            <label>Consignee's Address</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Items */}
                                                {state.items.map((item, index) => (
                                                    <div className="row mb-3" key={index}>
                                                        <div className="col-md-2">
                                                            <div className="form-floating">
                                                                <select
                                                                    className="form-select"
                                                                    name="description"
                                                                    value={item.description}
                                                                    onChange={(event) => handleChange(event, index)}
                                                                    required
                                                                >
                                                                    <option value="">Select a product</option>
                                                                    {state.products.map((product) => (
                                                                        <option
                                                                            key={product.id}
                                                                            value={product.title}
                                                                        >
                                                                            {product.title}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                                <label>Description</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <div className="form-floating">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="HSNCode"
                                                                    placeholder="HSN Code"
                                                                    value={item.HSNCode}
                                                                    onChange={(event) => handleChange(event, index)}
                                                                    required
                                                                />
                                                                <label>HSN Code</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <div className="form-floating">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="Rate"
                                                                    placeholder="Rate"
                                                                    value={item.Rate}
                                                                    onChange={(event) => handleChange(event, index)}
                                                                    required
                                                                />
                                                                <label>Rate</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <div className="form-floating">
                                                                <input
                                                                    type="number"
                                                                    className="form-control"
                                                                    name="quantity"
                                                                    placeholder="Quantity"
                                                                    value={item.quantity}
                                                                    onChange={(event) => handleChange(event, index)}
                                                                    required
                                                                />
                                                                <label>Quantity</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <div className="form-floating">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="amount"
                                                                    placeholder="Amount"
                                                                    value={item.quantity * item.Rate}
                                                                    disabled
                                                                />
                                                                <label>Amount</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-1">
                                                            <button
                                                                type="button"
                                                                className="btn btn-danger"
                                                                onClick={() => handleDeleteItem(index)}
                                                            >
                                                                Delete
                                                            </button>
                                                        </div>
                                                    </div>
                                                ))}

                                                <div className="row mb-3">
                                                    <div className="col-12 text-end">
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary"
                                                            onClick={handleAddItem}
                                                        >
                                                            Add Item
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <div className="col-md-4">
                                                        <div className="form-floating">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="SubTotal"
                                                                placeholder="Sub Total"
                                                                value={state.subTotal}
                                                                onChange={(event) =>
                                                                    setState((prevState) => ({
                                                                        ...prevState,
                                                                        subTotal: event.target.value,
                                                                    }))
                                                                }
                                                                required
                                                            />
                                                            <label>Sub Total</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-floating">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="SalesTax"
                                                                placeholder="Sales Tax"
                                                                value={state.SalesTax}
                                                                onChange={(event) =>
                                                                    setState((prevState) => ({
                                                                        ...prevState,
                                                                        SalesTax: event.target.value,
                                                                    }))
                                                                }
                                                                required
                                                            />
                                                            <label>Sales Tax</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-floating">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="TotalAmount"
                                                                placeholder="Total Amount"
                                                                value={state.TotalAmount}
                                                                onChange={(event) =>
                                                                    setState((prevState) => ({
                                                                        ...prevState,
                                                                        TotalAmount: event.target.value,
                                                                    }))
                                                                }
                                                                required
                                                            />
                                                            <label>Total Amount</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <div className="col-md-6">
                                                        <div className="form-floating">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="AmountInWords"
                                                                placeholder="Amount in Words"
                                                                value={state.AmountInWords}
                                                                onChange={(event) =>
                                                                    setState((prevState) => ({
                                                                        ...prevState,
                                                                        AmountInWords: event.target.value,
                                                                    }))
                                                                }
                                                                required
                                                            />
                                                            <label>Amount in Words</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-floating">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="AmountInFigure"
                                                                placeholder="Amount in Figure"
                                                                value={state.AmountInFigure}
                                                                onChange={(event) =>
                                                                    setState((prevState) => ({
                                                                        ...prevState,
                                                                        AmountInFigure: event.target.value,
                                                                    }))
                                                                }
                                                                required
                                                            />
                                                            <label>Amount in Figure</label>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="row mb-3">
                                                    <div className="col-md-6">
                                                        <div className="form-floating">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="InvoiceNo"
                                                                placeholder="Invoice Number"
                                                                value={`EXP-${state.products.length + 1}`}
                                                                onChange={(event) =>
                                                                    setState((prevState) => ({
                                                                        ...prevState,
                                                                        InvoiceNo: event.target.value,
                                                                    }))
                                                                }
                                                                required
                                                                disabled
                                                            />
                                                            <label>Invoice Number</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-floating">
                                                            <input
                                                                type="date"
                                                                className="form-control"
                                                                name="Date"
                                                                value={state.Date}
                                                                onChange={(event) =>
                                                                    setState((prevState) => ({
                                                                        ...prevState,
                                                                        Date: event.target.value,
                                                                    }))
                                                                }
                                                                required
                                                            />
                                                            <label>Date</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <div className="col-md-4">
                                                        <div className="form-floating">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="IECCode"
                                                                placeholder="IEC Code"
                                                                value={state.IECCode}
                                                                onChange={(event) =>
                                                                    setState((prevState) => ({
                                                                        ...prevState,
                                                                        IECCode: event.target.value,
                                                                    }))
                                                                }
                                                                required
                                                            />
                                                            <label>IEC Code</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-floating">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="GSTIN"
                                                                placeholder="GSTIN"
                                                                value={state.GSTIN}
                                                                onChange={(event) =>
                                                                    setState((prevState) => ({
                                                                        ...prevState,
                                                                        GSTIN: event.target.value,
                                                                    }))
                                                                }
                                                                required
                                                            />
                                                            <label>GSTIN</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-floating">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="PAN"
                                                                placeholder="PAN"
                                                                value={state.PAN}
                                                                onChange={(event) =>
                                                                    setState((prevState) => ({
                                                                        ...prevState,
                                                                        PAN: event.target.value,
                                                                    }))
                                                                }
                                                                required
                                                            />
                                                            <label>PAN</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <div className="col-md-6">
                                                        <div className="form-floating">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="BuyersOrderNo"
                                                                placeholder="Buyer's Order Number"
                                                                value={state.BuyersOrderNo}
                                                                onChange={(event) =>
                                                                    setState((prevState) => ({
                                                                        ...prevState,
                                                                        BuyersOrderNo: event.target.value,
                                                                    }))
                                                                }
                                                                required
                                                            />
                                                            <label>Buyer's Order Number</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-floating">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="BuyerNotCosignee"
                                                                placeholder="Buyer Not Cosignee"
                                                                value={state.BuyerNotCosignee}
                                                                onChange={(event) =>
                                                                    setState((prevState) => ({
                                                                        ...prevState,
                                                                        BuyerNotCosignee: event.target.value,
                                                                    }))
                                                                }

                                                            />
                                                            <label>Buyer Not Cosignee</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <div className="col-md-6">
                                                        <div className="form-floating">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="PreCarriageBy"
                                                                placeholder="Pre Carriage By"
                                                                value={state.PreCarriageBy}
                                                                onChange={(event) =>
                                                                    setState((prevState) => ({
                                                                        ...prevState,
                                                                        PreCarriageBy: event.target.value,
                                                                    }))
                                                                }
                                                                required
                                                            />
                                                            <label>Pre Carriage By</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-floating">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="LUTNo"
                                                                placeholder="LUT No"
                                                                value={state.LUTNo}
                                                                onChange={(event) =>
                                                                    setState((prevState) => ({
                                                                        ...prevState,
                                                                        LUTNo: event.target.value,
                                                                    }))
                                                                }
                                                                required
                                                            />
                                                            <label>LUT No</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <div className="col-md-6">
                                                        <div className="form-floating">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="FinalDestination"
                                                                placeholder="Final Destination"
                                                                value={state.FinalDestination}
                                                                onChange={(event) =>
                                                                    setState((prevState) => ({
                                                                        ...prevState,
                                                                        FinalDestination: event.target.value,
                                                                    }))
                                                                }
                                                                required
                                                            />
                                                            <label>Final Destination</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-floating">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="VesselFlightNo"
                                                                placeholder="Vessel Flight No"
                                                                value={state.VesselFlightNo}
                                                                onChange={(event) =>
                                                                    setState((prevState) => ({
                                                                        ...prevState,
                                                                        VesselFlightNo: event.target.value,
                                                                    }))
                                                                }
                                                                required
                                                            />
                                                            <label>Vessel Flight No</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <div className="col-md-6">
                                                        <div className="form-floating">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="PortOfLoading"
                                                                placeholder="Port Of Loading"
                                                                value={state.PortOfLoading}
                                                                onChange={(event) =>
                                                                    setState((prevState) => ({
                                                                        ...prevState,
                                                                        PortOfLoading: event.target.value,
                                                                    }))
                                                                }
                                                                required
                                                            />
                                                            <label>Port Of Loading</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-floating">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="PortOfDischarge"
                                                                placeholder="Port Of Discharge"
                                                                value={state.PortOfDischarge}
                                                                onChange={(event) =>
                                                                    setState((prevState) => ({
                                                                        ...prevState,
                                                                        PortOfDischarge: event.target.value,
                                                                    }))
                                                                }
                                                                required
                                                            />
                                                            <label>Port Of Discharge</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <div className="col-md-6">
                                                        <div className="form-floating">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="DeliPay"
                                                                placeholder="Deli Pay"
                                                                value={state.DeliPay}
                                                                onChange={(event) =>
                                                                    setState((prevState) => ({
                                                                        ...prevState,
                                                                        DeliPay: event.target.value,
                                                                    }))
                                                                }
                                                                required
                                                            />
                                                            <label>Deli Pay</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-floating">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="ContainerNo"
                                                                placeholder="Container No"
                                                                value={state.ContainerNo}
                                                                onChange={(event) =>
                                                                    setState((prevState) => ({
                                                                        ...prevState,
                                                                        ContainerNo: event.target.value,
                                                                    }))
                                                                }
                                                                required
                                                            />
                                                            <label>Container No</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <div className="col-12">
                                                        <div className="form-floating">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="Declaration"
                                                                placeholder="Declaration"
                                                                value={state.declaration}
                                                                onChange={(event) =>
                                                                    setState((prevState) => ({
                                                                        ...prevState,
                                                                        Declaration: event.target.value,
                                                                    }))
                                                                }
                                                                required
                                                            />
                                                            <label>Declaration</label>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="row">
                                                    <div className="col-12 text-end">
                                                        <button type="submit" className="btn btn-primary">
                                                            Add Sale
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
