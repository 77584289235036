import React, { useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import './index.css';
import AdminProduct from './AdminProduct';
import AdminAddProduct from './AdminAddProduct';
import AdminEditProduct from './AdminEditProduct';
import NewSale from './NewSale';
import SalesPage from './AdminSales';
import ViewSalesPage from './ViewSale';
import Logout from './Logout'; // Import Logout component
import 'react-toastify/dist/ReactToastify.css';
import 'boxicons/css/boxicons.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';  // if using Bootstrap

import { withCookies } from 'react-cookie';

const root = ReactDOM.createRoot(document.getElementById('root'));

function MyRouter() {

  // Disable right-click context menu globally
  useEffect(() => {
    const handleContextMenu = (event) => {
      event.preventDefault();
    };

    window.addEventListener('contextmenu', handleContextMenu);

    return () => {
      window.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        {/* No longer checking for isAuthenticated, rendering components directly */}
        <Route path="/" element={<AdminProduct />} />
        <Route path="/add-product" element={<AdminAddProduct />} />
        <Route path="/edit-product/:productid" element={<AdminEditProduct />} />
        <Route path="/New-Sale" element={<NewSale />} />
        <Route path="/sales" element={<SalesPage />} />
        <Route path="/View-Sale/:id" element={<ViewSalesPage />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
}

const MyRouterWithCookies = withCookies(MyRouter);
root.render(<MyRouterWithCookies />);
