import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Navbar, Container, Offcanvas, Nav } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'; // Bootstrap CSS
import 'boxicons/css/boxicons.min.css'; // BoxIcons CSS

export default function AdminMenu() {
  const location = useLocation(); // Hook to get the current route

  // Function to determine if the link is active
  const isActive = (path) => location.pathname === path;

  return (
    <>
      {/* Navbar for Mobile Devices */}
      <Navbar bg="light" expand="lg" className="d-lg-none shadow-sm">
        <Container fluid>
          <Navbar.Brand as={Link} to="/" className="fw-bolder">
            Arabia International
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="offcanvasNavbar" />
          <Navbar.Offcanvas
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
            placement="start"
            className="bg-light"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id="offcanvasNavbarLabel">Menu</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="flex-column">
                {/* Sales Link */}
                <Nav.Link
                  as={Link}
                  to="/sales"
                  className={`d-flex align-items-center mb-2 ${isActive('/sales') ? 'active bg-primary text-white rounded' : 'text-dark'}`}
                >
                  <i className="bx bx-home-circle me-2"></i> Sales
                </Nav.Link>
                
                {/* New Sales Link */}
                <Nav.Link
                  as={Link}
                  to="/New-Sale"
                  className={`d-flex align-items-center mb-2 ${isActive('/New-Sale') ? 'active bg-primary text-white rounded' : 'text-dark'}`}
                >
                  <i className="bx bx-plus-circle me-2"></i> New Sales
                </Nav.Link>
                
                {/* Products Link */}
                <Nav.Link
                  as={Link}
                  to="/products"
                  className={`d-flex align-items-center mb-2 ${isActive('/products') ? 'active bg-primary text-white rounded' : 'text-dark'}`}
                >
                  <i className="bx bxs-gift me-2"></i> Products
                </Nav.Link>
                
                {/* Logout Link */}
                <Nav.Link
                  as={Link}
                  to="/logout"
                  className={`d-flex align-items-center ${isActive('/logout') ? 'active bg-primary text-white rounded' : 'text-dark'}`}
                >
                  <i className="bx bxs-log-out me-2"></i> Logout
                </Nav.Link>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>

      {/* Sidebar for Desktop Devices */}
      <div className="d-none d-lg-flex bg-light vh-100 flex-column shadow-sm" style={{ width: '250px' }}>
        {/* Brand Section */}
        <div className="p-4">
          <Link to="/" className="d-flex align-items-center text-decoration-none">
            {/* Optional: Add your SVG logo here */}
            <span className="fw-bolder fs-4">Arabia International</span>
          </Link>
        </div>
        <hr className="my-0" />
        
        {/* Navigation Links */}
        <nav className="nav flex-column p-3">
          {/* Sales Link */}
          <Link
            to="/sales"
            className={`nav-link d-flex align-items-center mb-2 ${isActive('/sales') ? 'active bg-primary text-white rounded' : 'text-dark'}`}
          >
            <i className="bx bx-home-circle me-2"></i> Sales
          </Link>
          
          {/* New Sales Link */}
          <Link
            to="/New-Sale"
            className={`nav-link d-flex align-items-center mb-2 ${isActive('/New-Sale') ? 'active bg-primary text-white rounded' : 'text-dark'}`}
          >
            <i className="bx bx-plus-circle me-2"></i> New Sales
          </Link>
          
          {/* Products Link */}
          <Link
            to="/products"
            className={`nav-link d-flex align-items-center mb-2 ${isActive('/products') ? 'active bg-primary text-white rounded' : 'text-dark'}`}
          >
            <i className="bx bxs-gift me-2"></i> Products
          </Link>
          
          {/* Logout Link */}
          <Link
            to="/logout"
            className={`nav-link d-flex align-items-center ${isActive('/logout') ? 'active bg-primary text-white rounded' : 'text-dark'}`}
          >
            <i className="bx bxs-log-out me-2"></i> Logout
          </Link>
        </nav>
        
        
      </div>
    </>
  );
}
