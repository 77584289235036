import React, { useState, useEffect } from "react";
import axios from "axios";
import AdminMenu from "./AdminMenu";
import showError from "./toast-message";
import { ToastContainer } from "react-toastify";
import { Link } from "react-router-dom";
import BaseUrl from "./BaseUrl";
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const SalesPage = () => {
  const [salesData, setSalesData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSalesData = async () => {
      try {
        const response = await axios.get(`${BaseUrl()}sales`);
        setSalesData(response.data.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching sales data:', error);
        showError('Failed to fetch sales data');
        setLoading(false);
      }
    };

    fetchSalesData();
  }, []);

  return (
    <div className="d-flex min-vh-100 bg-light">
      <AdminMenu />
      <div className="flex-grow-1 p-4">
        <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} />
        <h1 className="fw-bold mb-4 text-dark">Sales Data</h1>

        {loading ? (
          <p className="loading-message">Loading Sales Data...</p>
        ) : (
          <div className="card shadow mb-4">
            <div className="card-header" style={{ backgroundColor: '#007bff', color: '#fff' }}>
              <h5 className="mb-0">Sales Records</h5>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-striped table-hover">
                  <thead>
                    <tr>
                      <th scope="col">ID</th>
                      <th scope="col">Date</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Buyer's Order No</th>
                      <th scope="col">Customer Address</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {salesData.length > 0 ? (
                      salesData.map(sale => (
                        <tr key={sale.id}>
                          <td>{sale.id}</td>
                          <td>{new Date(sale.Date).toLocaleDateString()}</td>
                          <td>${sale.Amount ? sale.Amount.toFixed(2) : '0.00'}</td>
                          <td>{sale.BuyersOrderNo}</td>
                          <td>{sale.CAddress}</td>
                          <td>
                            <Link to={`/View-Sale/${sale.id}`} className="btn btn-info btn-sm">
                              View
                            </Link>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="6" className="text-center">
                          No sales records found.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SalesPage;
