import React, { useState, useEffect } from "react";
import axios from "axios";
import showError from "./toast-message";
import 'react-toastify/dist/ReactToastify.css';
import { useParams } from "react-router-dom";
import BaseUrl from "./BaseUrl";


const ViewSalesPage = () => {
  const { id } = useParams();
  const [saleData, setSaleData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSaleData = async () => {
      try {
        const response = await axios.get(`${BaseUrl()}sales/${id}`);
        setSaleData(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching sale data:', error);
        showError('Failed to fetch sale data');
        setLoading(false);
      }
    };



    fetchSaleData();


  }, [id]);



  const handlePrint = () => {
    window.print();
  };

  return (
    <>
      <div className="container">
      <h1 className="text-center mb-4">Arabia International</h1>
      {loading ? (
        <p>Loading...</p>
      ) : saleData ? (

        <React.Fragment>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th colSpan={2}>Exporter</th>
                <th colSpan={4}>Consignee</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan={2}>
                  {saleData.EAddress}
                  <br />
                  <h6>GSTIN: 24AJEPM8902A1ZN</h6>
                </td>
                <td colSpan={4}>
                  {saleData.CAddress}
                </td>

              </tr>
              <tr>
                <th colSpan={6}>Invoice Details</th>
              </tr>
              <tr>
                <td>Invoice No.</td>
                <td>{saleData.InvoiceNo}</td>
                <td>Date</td>
                <td colSpan={3}>{saleData.Date}</td>
              </tr>
              <tr>
                <td>IEC No.</td>
                <td>2412011842</td>
                <td>GSTIN</td>
                <td colSpan={3}>24AJEPM8902A1ZN</td>
              </tr>
              <tr>
                <td>Buyer's Order No.</td>
                <td>E-BAY</td>
                <td>Buyer if other than consignee</td>
                <td colSpan={3}>SAME AS CONESS</td>
              </tr>
              <tr>
                <td>Pre-Carriage by : <br /> {saleData.PreCarriageBy} </td>
                <td colSpan={2}>Pre-carrier place of receipt :-  {saleData.PreCarriageBy}</td>
                <td colSpan={3}>LUT NO: AD240424014058H</td>
              </tr>
              <tr>
                <th colSpan={6}>Shipment Details</th>
              </tr>
              <tr>
                <td>Country of Final Destination</td>
                <td>{saleData.FinalDestination}</td>
                <td>Vessel/Flight No</td>
                <td colSpan={3}>{saleData.VesselFlightNo}</td>
              </tr>
              <tr>
                <td>Port of Loading</td>
                <td>{saleData.PortOfLoading}</td>
                <td>Port Of Discharge :- <br /> {saleData.PortOfDischarge}</td>
                <td>Terms of Delivery &amp; Payment</td>
                <td colSpan={2}>PAYMENT 100% ADVANCE</td>
              </tr>
              <tr>
                <th colSpan={6}>Product Details</th>
              </tr>
              <tr>
                <th>Marks &amp; Nos/Container No.</th>
                <th>HSNCode</th>
                <th>Description</th>
                <th>Quantity</th>
                <th>Rate ($)</th>
                <th>Amount ($)</th>
              </tr>
              <tr>
                <td>{saleData.ContainerNo}</td>
                <td>{saleData.HSNCode}</td>
                <td>{saleData.Products}</td>
                <td>{saleData.items}</td>
                <td>{saleData.subTotal}</td>
                <td>{saleData.subTotal}</td>
              </tr>
              <tr>
                <th colSpan={6}>Total Value</th>
              </tr>
              <tr>
                <td colSpan={2}>{saleData.subTotal}</td>
                <td colSpan={4}>{saleData.subTotal}</td>
              </tr>
              <tr>
                <td colSpan={2}>Sales Tax</td>
                <td colSpan={4}>{saleData.SalesTax}</td>
              </tr>
              <tr>
                <td colSpan={2}>Amount Chargeable (in figures)</td>
                <td colSpan={4}>{saleData.amountInFigure}</td>
              </tr>
              <tr>
                <td colSpan={2}>Amount Chargeable (in words)</td>
                <td colSpan={4}>{saleData.amountInWords}</td>
              </tr>
              <tr>
                <td colSpan={2} className="p-5">
                  {saleData.declaration}
                </td>
                <td colSpan={4} className="p-5">
                  Arabia INTERNATIONAL
                </td>
              </tr>
            </tbody>
          </table>
          <button className="btn btn-primary d-print-none" onClick={handlePrint}>Print</button>

        </React.Fragment>
      ) : (
        <p>No data available</p>
      )}
    </div>


    </>

  );

};

export default ViewSalesPage;
